@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.documents {
  padding: 120px 0 80px;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_content {
    gap: 16px;
    grid-gap: 16px;
    margin-top: 60px;
    @include desktopStyle {
      gap: get-vw(16px);
      grid-gap: get-vw(16px);
      margin-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      gap: 12px;
      grid-gap: 12px;
    }
  }

  :global {
    .document-link {
      min-width: calc(33.333% - 11px);
      flex: 1;
      @include desktopStyle {
        min-width: calc(33.333% - get-vw(11px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: calc(50% - 8px);
        flex: none;
      }
      @media only screen and (max-width: $bp-phone) {
        width: 100%;
      }
    }
  }
}
